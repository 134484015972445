a {
  /* color: #c98ffd;
  text-decoration: none; */
  text-decoration: none;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

a:hover {
  /* text-decoration: underline; */
}

.center {
  text-align: center;
}

.indent {
  margin: 5px 20px !important;
}

.col {
  margin: 15px 0;
}

.quote {
  text-align: center;
    font-family: 'Inria Serif';
    font-style: italic;
    font-size: 18px;
    line-height: 25px;
    color: #4e4e4e;
}