/* Fade In */
 

.fade-in {
    animation: fadeIn ease .5s;
    -webkit-animation: fadeIn ease .5s;
    -moz-animation: fadeIn ease .5s;
    -o-animation: fadeIn ease .5s;
    -ms-animation: fadeIn ease .5s;
  }

.glitch-overlay.fade-in {
  animation: fadeInGlitch ease .5s;
  -webkit-animation: fadeInGlitch ease .5s;
  -moz-animation: fadeInGlitch ease .5s;
  -o-animation: fadeInGlitch ease .5s;
  -ms-animation: fadeInGlitch ease .5s;
}
   

@keyframes fadeInGlitch{
  0% {
    opacity:0;
  }
  100% {
    opacity: 0.5;
  }
}
 
@-moz-keyframes fadeInGlitch {
  0% {
    opacity:0;
  }
  100% {
    opacity: 0.5;
  }
}
 
@-webkit-keyframes fadeInGlitch {
  0% {
    opacity:0;
  }
  100% {
    opacity: 0.5;
  }
}
 
@-o-keyframes fadeInGlitch {
  0% {
    opacity:0;
  }
  100% {
    opacity: 0.5;
  }
}
 
@-ms-keyframes fadeInGlitch {
  0% {
    opacity:0;
  }
  100% {
    opacity: 0.5;
  }
}
   
  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
   
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
   
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
   
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
   
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
 
  /* End of Fade In */
 
  /* Slide right */
.slide-right {
    position: relative;
    left: -100vw;
    -webkit-animation: slide 0.2s forwards;
    animation: slide 0.2s forwards;
}
 
@-webkit-keyframes slide {
    100% { left: 0; }
}
 
@keyframes slide {
    100% { left: 0; }
}
/* End of slide right */
 
/* Slide Up and Fade In (Footer) */
 
.slide-up-fade-in{
    animation: slide-up-fade-in ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:forwards; /*when the spec is finished*/
    -webkit-animation: slide-up-fade-in ease 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/
    -moz-animation: slide-up-fade-in ease 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: slide-up-fade-in ease 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: slide-up-fade-in ease 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
 
    opacity:0;
    opacity: 1\9;
 
}
 
@keyframes slide-up-fade-in{
    0% {
        opacity:0;
        transform:  translate(0px,40px)  ;
    }
    100% {
        opacity:1;
        transform:  translate(0px,0px)  ;
    }
}
 
@-moz-keyframes slide-up-fade-in{
    0% {
        opacity:0;
        -moz-transform:  translate(0px,40px)  ;
    }
    100% {
        opacity:1;
        -moz-transform:  translate(0px,0px)  ;
    }
}
 
@-webkit-keyframes slide-up-fade-in {
    0% {
        opacity:0;
        -webkit-transform:  translate(0px,40px)  ;
    }
    100% {
        opacity:1;
        -webkit-transform:  translate(0px,0px)  ;
    }
}
 
@-o-keyframes slide-up-fade-in {
    0% {
        opacity:0;
        -o-transform:  translate(0px,40px)  ;
    }
    100% {
        opacity:1;
        -o-transform:  translate(0px,0px)  ;
    }
}
 
@-ms-keyframes slide-up-fade-in {
    0% {
        opacity:0;
        -ms-transform:  translate(0px,40px)  ;
    }
    100% {
        opacity:1;
        -ms-transform:  translate(0px,0px)  ;
    }
} 
 
/* End of Slide Up and Fade In (Footer) */
 
/* Fade Down */
 
.fade-down{
    animation: fadeDown ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode:forwards; /*when the spec is finished*/
    -webkit-animation: fadeDown ease 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/
    -moz-animation: fadeDown ease 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode:forwards; /*FF 5+*/
    -o-animation: fadeDown ease 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode:forwards; /*Not implemented yet*/
    -ms-animation: fadeDown ease 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode:forwards; /*IE 10+*/
}
   
@keyframes fadeDown{
0% {
    opacity:0;
    transform:  translate(0px,-25px)  ;
}
100% {
    opacity:1;
    transform:  translate(0px,0px)  ;
}
}
 
@-moz-keyframes fadeDown{
0% {
    opacity:0;
    -moz-transform:  translate(0px,-25px)  ;
}
100% {
    opacity:1;
    -moz-transform:  translate(0px,0px)  ;
}
}
 
@-webkit-keyframes fadeDown {
0% {
    opacity:0;
    -webkit-transform:  translate(0px,-25px)  ;
}
100% {
    opacity:1;
    -webkit-transform:  translate(0px,0px)  ;
}
}
 
@-o-keyframes fadeDown {
0% {
    opacity:0;
    -o-transform:  translate(0px,-25px)  ;
}
100% {
    opacity:1;
    -o-transform:  translate(0px,0px)  ;
}
}
 
@-ms-keyframes fadeDown {
0% {
    opacity:0;
    -ms-transform:  translate(0px,-25px)  ;
}
100% {
    opacity:1;
    -ms-transform:  translate(0px,0px)  ;
}
}
 
/* End of Fade Down */
 
 
/* Blur */
 
.blur {
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
    top: 0;
    position: fixed;
    left: 0;
 
    height: 100%;
    width: 100%;
    z-index: 2;
}
 
/* End of Blur */